<template>
  <LineItemChecker dialog-name="Line Item Checker" />
</template>
<script>
import LineItemChecker from '@/views/app/checker/LineItem'

export default {
  name: 'LineItemWrapper',
  components: {
    LineItemChecker
  }
}
</script>
