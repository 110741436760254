<template>
  <o-container ref="container">
    <v-card elevation="2" shaped class="o-container-content">
      <v-card-title class="d-flex justify-space-between">
        <div class="text-uppercase pl-4 mr-auto ">
          {{ title }}
        </div>
        <div class="d-flex justify-end ml-auto pr-0 pb-2">
          <o-button
            :busy="busy"
            color="warning"
            icon="mdi-filter"
            type="label-icon"
            label="filter"
            @on-click="showFilter = true"
          />

          <o-button
            :busy="busy"
            color="primary"
            icon="mdi-sync"
            type="label-icon"
            label="Refresh"
            @on-click="reset()"
          />

          <o-drawer
            v-if="!busy"
            :auto-close="true"
            :actions="[
              {
                key: 'on-item-search',
                label: 'SEARCH',
                icon: 'mdi-text-box-search-outline',
                color: 'success',
              },
            ]"
            button-icon="mdi-filter-plus-outline"
            :title="`FILTERS`"
            :use-button="false"
            width="25vw"
            :visible="showFilter"
            @on-item-search="reset()"
            @on-close="showFilter = false"
          >
            <v-row class="pa-0 ma-0">
              <v-col cols="12">
                <o-input
                  v-model="line_item_id"
                  :busy="busy"
                  label="Line Item Id"
                />
              </v-col>
            </v-row>
          </o-drawer>
        </div>
      </v-card-title>
      <v-divider />

      <v-skeleton-loader
        v-if="busy"
        :loading="busy"
        type="table"
        height=""
        transition="v-scroll-x-transition"
      />

      <v-card-subtitle v-if="data && !data.advertiser" class="text-center">
        <span class="text-red">No Data. Use Filter.</span>
      </v-card-subtitle>

      <template>
        <v-card-subtitle class="mt-10">
          ADVERTISER
        </v-card-subtitle>
        <o-table
          v-if="data && data.advertiser"
          class="pa-0 ma-0 pl-10 pr-10"
          type="array"
          :data="[data.advertiser]"
          :no-count="true"
          :headers="[
            {
              text: 'Account ID',
              align: 'end',
              sortable: false,
              value: 'account_id',
              type: 'text',
              width: 100,
            },
            {
              text: 'Account Name',
              align: 'start',
              sortable: false,
              value: 'account_name',
              type: 'text',
            },
            {
              text: 'Advertiser ID',
              align: 'end',
              sortable: false,
              value: 'advertiser_id',
              type: 'text',
              width: 100,
            },
            {
              text: 'Advertiser NAme',
              align: 'start',
              sortable: false,
              value: 'advertiser_name',
              type: 'text',
            },
            {
              text: 'Active',
              align: 'center',
              sortable: false,
              value: 'active',
              type: 'boolean',
            },
          ]"
        />

        <v-card-subtitle class="mt-10">
          CAMPAIGN
        </v-card-subtitle>

        <o-table
          v-if="data && data.campaign"
          class="pa-0 ma-0 pl-10 pr-10"
          type="array"
          :data="[data.campaign]"
          :no-count="true"
          :headers="[
            {
              text: 'ID',
              align: 'end',
              sortable: false,
              value: 'campaign_id',
              type: 'text',
              width: 100,
            },
            {
              text: 'Name',
              align: 'start',
              sortable: false,
              value: 'campaign_name',
              type: 'text',
            },
            {
              text: 'Budget',
              align: 'end',
              sortable: false,
              value: 'campaign_budget',
              type: 'slot',
            },
            {
              text: 'Line Item Spend',
              align: 'end',
              sortable: false,
              value: 'line_items_spend',
              type: 'slot',
            },
            {
              text: 'Active',
              align: 'center',
              sortable: false,
              value: 'active',
              type: 'boolean',
            },
            {
              text: 'Start Date',
              align: 'start',
              sortable: false,
              value: 'start_date',
              type: 'slot',
            },
            {
              text: 'End Date',
              align: 'start',
              sortable: false,
              value: 'end_date',
              type: 'slot',
            },
            {
              value: 'action',
              type: 'action',
              actions: [
                {
                  key: 'on-item-edit',
                  label: 'Campaign Details',
                  icon: 'mdi-format-list-bulleted-square',
                  color: 'success',
                },
              ],
            },
          ]"
          @on-item-edit="edit"
        >
          <template v-slot:slot="row">
            <div
              v-if="
                row.prop.key === 'start_date' || row.prop.key === 'end_date'
              "
              style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
            >
              <span
                :class="[
                  compare(row.prop.item.start_date, row.prop.item.end_date),
                ]"
                >{{ getDateTime(row.prop.item[row.prop.key]) }}</span
              >
            </div>
            <div
              v-if="
                row.prop.key === 'campaign_budget' ||
                  row.prop.key === 'line_items_spend'
              "
              class="text-right"
              style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
            >
              <template v-if="row.prop.item['budget_type'] === 1">
                <span
                  v-if="
                    row.prop.item['campaign_budget'] <=
                      row.prop.item['line_items_spend']
                  "
                  class="text-danger"
                >
                  {{ getInteger(row.prop.item[row.prop.key]) }}
                </span>
                <span v-else>{{
                  getInteger(row.prop.item[row.prop.key])
                }}</span>
              </template>
              <template v-if="row.prop.item['budget_type'] === 2">
                <span
                  v-if="
                    row.prop.item['campaign_budget'] <=
                      row.prop.item['line_items_spend']
                  "
                  class="text-danger"
                >
                  ${{ getFloat(row.prop.item[row.prop.key]) }}
                </span>
                <span v-else>${{ getFloat(row.prop.item[row.prop.key]) }}</span>
              </template>
            </div>
          </template>
        </o-table>

        <v-card-subtitle class="mt-10">
          LINE ITEM
        </v-card-subtitle>

        <o-table
          v-if="data && data.line_item"
          class="pa-0 ma-0 pl-10 pr-10"
          type="array"
          :data="[data.line_item]"
          :no-count="true"
          :headers="[
            {
              text: 'ID',
              align: 'end',
              sortable: false,
              value: 'line_item_id',
              type: 'text',
              width: 100,
            },
            {
              text: 'Name',
              align: 'start',
              sortable: false,
              value: 'line_item_name',
              type: 'text',
            },
            {
              text: 'Active',
              align: 'middle',
              sortable: false,
              value: 'active',
              type: 'boolean',
            },
            {
              text: 'Start Date',
              align: 'start',
              sortable: false,
              value: 'start_date',
              type: 'slot',
            },
            {
              text: 'End Date',
              align: 'start',
              sortable: false,
              value: 'end_date',
              type: 'slot',
            },
            {
              text: 'Bidding Status',
              align: 'middle',
              sortable: false,
              value: 'bidding_status',
              type: 'boolean',
            },
          ]"
        >
          <template v-slot:slot="row">
            <div
              v-if="
                row.prop.key === 'start_date' || row.prop.key === 'end_date'
              "
              style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
            >
              <span
                :class="[
                  compare(row.prop.item.start_date, row.prop.item.end_date),
                ]"
                >{{ getDateTime(row.prop.item[row.prop.key]) }}</span
              >
            </div>
          </template>
        </o-table>

        <template >
              <v-card-subtitle                   
                class="mt-10"
              >
                <span :class="[(data.bidding_status && data.bidding_status.passed ? 'text-green' : 'text-red')]">BIDDING STATUS SUMMARY</span>
              </v-card-subtitle>
              <o-table
                v-if="
                  data &&
                    !busy && data.bidding_status
                "
                class="pa-0 ma-0 pl-10 pr-10"
                type="array"
                :data="data.bidding_status.conditions"
                :no-count="true"
                :headers="[
                  {
                    text: 'Passed',
                    align: 'start',
                    sortable: false,
                    value: 'status',
                    type: 'boolean',
                  },
                  {
                    text: 'Description',
                    align: 'start',
                    sortable: false,
                    value: 'condition',
                    type: 'text',
                  }
                ]"
              /> 
        </template>

        <v-card-subtitle
          v-if="data && !busy && data.creative_line_items"
          class="mt-10"
        >
          CREATIVE LINE ITEM
        </v-card-subtitle>
        
        <o-table
          v-if="data && data.creative_line_items"
          class="pa-0 ma-0 pl-10 pr-10"
          :fixed-header="false"
          type="array"
          deleted-at="cli_deleted_at"
          :data="data.creative_line_items"
          :no-count="true"
          :headers="[
            {
              text: 'Creative ID',
              align: 'end',
              sortable: false,
              value: 'creative_id',
              type: 'text',
              width: 100,
            },
            {
              text: 'Creative Name',
              align: 'start',
              sortable: false,
              value: 'creative_name',
              type: 'text',
            },
            {
              text: 'Creative Active',
              align: 'center',
              sortable: false,
              value: 'active',
              type: 'boolean',
            },
            {
              text: 'Creative Start Date',
              align: 'center',
              sortable: false,
              value: 'start_date',
              type: 'slot',
            },
            {
              text: 'Creative End Date',
              align: 'center',
              sortable: false,
              value: 'end_date',
              type: 'slot',
            },

            {
              text: 'CLI ID',
              align: 'end',
              sortable: false,
              value: 'cli_id',
              type: 'text',
              width: 100,
            },
            {
              text: 'Weighting',
              align: 'end',
              sortable: false,
              value: 'cli_weighting',
              type: 'decimal',
            },
            {
              text: 'CLI Start Date',
              align: 'start',
              sortable: false,
              value: 'cli_start_date',
              type: 'slot',
            },
            {
              text: 'CLI End Date',
              align: 'start',
              sortable: false,
              value: 'cli_end_date',
              type: 'slot',
            },
 
            {
              text: 'Deleted Date',
              align: 'center',
              sortable: false,
              value: 'cli_deleted_at',
              type: 'date-time',
            },
            {
              text: 'Deleted By',
              align: 'start',
              sortable: false,
              value: 'cli_deleted_by',
              type: 'text',
            },
            //{ text: 'BUDGET LIMIT', align:'middle', sortable: false, value: 'are_spends_beyond_campaign_budget', type: 'boolean' },
          ]"
        >
          <template v-slot:slot="row">
            <div
              v-if="
                row.prop.key === 'start_date' || row.prop.key === 'end_date'
              "
              style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
            >
              <span
                :class="[
                  compare(row.prop.item.start_date, row.prop.item.end_date),
                ]"
                >{{ getDateTime(row.prop.item[row.prop.key]) }}</span
              >
            </div>
            <div
              v-if="
                row.prop.key === 'cli_start_date' ||
                  row.prop.key === 'cli_end_date'
              "
              style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
            >
              <span
                :class="[
                  compare(
                    row.prop.item.cli_start_date,
                    row.prop.item.cli_end_date
                  ),
                ]"
                >{{ getDateTime(row.prop.item[row.prop.key]) }}</span
              >
            </div>
          </template>
        </o-table>

        <v-card-subtitle  class="mt-10">
          TRAFFIC STATS
        </v-card-subtitle>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <template v-if="data.today">
                <v-row class="ma-0 pa-0">
                  <v-col
                    cols="12"
                    xs="12"
                    sm="6"
                    md="6"
                    lg="4"
                    xl="2"
                    class="ma-0 pa-1"
                  >
                    <o-widget
                      icon="mdi-eye-outline"
                      background="warning"
                      text="Impression"
                      :value="getInteger(data.today[0].impressions || 0)"
                      :inner-icon="
                        `mdi-arrow-${
                          data.today[0].impressions_up ? 'up' : 'down'
                        }-bold-outline`
                      "
                      :inner-icon-color="
                        data.today[0].impressions_up
                          ? 'green--text'
                          : 'red--text'
                      "
                      :sheet-title="
                        getInteger(data.today[0].yesterday_impressions || 0)
                      "
                      :sheet-text="
                        `${
                          data.today[0].percent_impressions > 0 ? '+' : ''
                        }${getFloat(data.today[0].percent_impressions || 0)}`
                      "
                    />
                  </v-col>

                  <v-col
                    cols="12"
                    xs="12"
                    sm="6"
                    md="6"
                    lg="4"
                    xl="2"
                    class="ma-0 pa-1"
                  >
                    <o-widget
                      icon="mdi-currency-usd"
                      background="light-blue darken-1"
                      text="Spends"
                      :value="`$${getFloat(data.today[0].spends || 0)}`"
                      :inner-icon="
                        `mdi-arrow-${
                          data.today[0].spends_up ? 'up' : 'down'
                        }-bold-outline`
                      "
                      :inner-icon-color="
                        data.today[0].spends_up ? 'green--text' : 'red--text'
                      "
                      :sheet-title="
                        getFloat(data.today[0].yesterday_spends || 0)
                      "
                      :sheet-text="
                        `${
                          data.today[0].percent_spends > 0 ? '+' : ''
                        }${getFloat(data.today[0].percent_spends || 0)}`
                      "
                    />
                  </v-col>

                  <v-col
                    cols="12"
                    xs="12"
                    sm="6"
                    md="6"
                    lg="4"
                    xl="2"
                    class="ma-0 pa-1"
                  >
                    <o-widget
                      icon="mdi-trophy-outline"
                      background="blue-grey darken-1"
                      text="Wins"
                      :value="getInteger(data.today[0].wins || 0)"
                      :inner-icon="
                        `mdi-arrow-${
                          data.today[0].wins_up ? 'up' : 'down'
                        }-bold-outline`
                      "
                      :inner-icon-color="
                        data.today[0].wins_up ? 'green--text' : 'red--text'
                      "
                      :sheet-title="
                        getInteger(data.today[0].yesterday_wins || 0)
                      "
                      :sheet-text="
                        `${data.today[0].percent_wins > 0 ? '+' : ''}${getFloat(
                          data.today[0].percent_wins || 0
                        )}`
                      "
                    />
                  </v-col>

                  <v-col
                    cols="12"
                    xs="12"
                    sm="6"
                    md="6"
                    lg="4"
                    xl="2"
                    class="ma-0 pa-1"
                  >
                    <o-widget
                      icon="mdi-tag-outline"
                      background="lime darken-1"
                      text="CPM"
                      :value="`$${getFloat(data.today[0].cpm || 0)}`"
                      :inner-icon="
                        `mdi-arrow-${
                          data.today[0].cpm_up ? 'up' : 'down'
                        }-bold-outline`
                      "
                      :inner-icon-color="
                        data.today[0].cpm_up ? 'green--text' : 'red--text'
                      "
                      :sheet-title="getFloat(data.today[0].yesterday_cpm || 0)"
                      :sheet-text="
                        `${data.today[0].percent_cpm > 0 ? '+' : ''}${getFloat(
                          data.today[0].percent_cpm || 0
                        )}`
                      "
                    />
                  </v-col>

                  <v-col
                    cols="12"
                    xs="12"
                    sm="6"
                    md="6"
                    lg="4"
                    xl="2"
                    class="ma-0 pa-1"
                  >
                    <o-widget
                      icon="mdi-video-outline"
                      background="green darken-1"
                      text="VCR"
                      :value="getPercent(data.today[0].vcr || 0)"
                      :inner-icon="
                        `mdi-arrow-${
                          data.today[0].vcr_up ? 'up' : 'down'
                        }-bold-outline`
                      "
                      :inner-icon-color="
                        data.today[0].vcr_up ? 'green--text' : 'red--text'
                      "
                      :sheet-title="getFloat(data.today[0].yesterday_vcr || 0)"
                      :sheet-text="
                        `${data.today[0].percent_vcr > 0 ? '+' : ''}${getFloat(
                          data.today[0].percent_vcr || 0
                        )}`
                      "
                    />
                  </v-col>

                  <v-col
                    cols="12"
                    xs="12"
                    sm="6"
                    md="6"
                    lg="4"
                    xl="2"
                    class="ma-0 pa-1"
                  >
                    <o-widget
                      icon="mdi-content-copy"
                      background="orange darken-1"
                      text="Bids"
                      :value="getInteger(data.today[0].bids || 0)"
                      :inner-icon="
                        `mdi-arrow-${
                          data.today[0].bids_up ? 'up' : 'down'
                        }-bold-outline`
                      "
                      :inner-icon-color="
                        data.today[0].bids_up ? 'green--text' : 'red--text'
                      "
                      :sheet-title="
                        getInteger(data.today[0].yesterday_bids || 0)
                      "
                      :sheet-text="
                        `${data.today[0].percent_bids > 0 ? '+' : ''}${getFloat(
                          data.today[0].percent_bids || 0
                        )}`
                      "
                    />
                  </v-col>
                </v-row>
              </template>
            </v-col>

            <v-col 
              cols="12"
              xs="12"
              sm="12"
              md="12" 
              lg="6"
              xl="4"
            >
              <base-card> 
                <v-card-title class="d-flex justify-space-between">
                  <div class="mb-0 mr-4">
                    <p class="card-title mb-0 text-uppercase">
                      IMPRESSIONS
                    </p>
                  </div>
                </v-card-title>
                <v-divider />
              
                <v-card-text>
                  <apexchart
                    ref="hourlyImpressions"
                    type="line"
                    height="200"
                    :options="hourlyImpressions.chartOptions"
                    :series="hourlyImpressions.series"
                  />
                </v-card-text> 
              </base-card>
            </v-col> 

            <v-col 
              cols="12"
              xs="12"
              sm="12"
              md="12" 
              lg="6"
              xl="4"
            >
              <base-card> 
                <v-card-title class="d-flex justify-space-between">
                  <div class="mb-0 mr-4">
                    <p class="card-title mb-0 text-uppercase">
                      SPENDS
                    </p>
                  </div>
                </v-card-title>
                <v-divider />
              
                <v-card-text>
                  <apexchart
                    ref="hourlySpends"
                    type="line"
                    height="200"
                    :options="hourlySpends.chartOptions"
                    :series="hourlySpends.series"
                  />
                </v-card-text> 
              </base-card>
            </v-col> 

            <v-col 
              cols="12"
              xs="12"
              sm="12"
              md="12" 
              lg="6"
              xl="4"
            >
              <base-card> 
                <v-card-title class="d-flex justify-space-between">
                  <div class="mb-0 mr-4">
                    <p class="card-title mb-0 text-uppercase">
                      CPM
                    </p>
                  </div>
                </v-card-title>
                <v-divider />
              
                <v-card-text>
                  <apexchart
                    ref="hourlyCpm"
                    type="line"
                    height="200"
                    :options="hourlyCpm.chartOptions"
                    :series="hourlyCpm.series"
                  />
                </v-card-text> 
              </base-card>
            </v-col> 

            <v-col 
              cols="12"
              xs="12"
              sm="12"
              md="12" 
              lg="6"
              xl="4"
            >
              <base-card> 
                <v-card-title class="d-flex justify-space-between">
                  <div class="mb-0 mr-4">
                    <p class="card-title mb-0 text-uppercase">
                      VCR
                    </p>
                  </div>
                </v-card-title>
                <v-divider />
              
                <v-card-text>
                  <apexchart
                    ref="hourlyVcr"
                    type="line"
                    height="200"
                    :options="hourlyVcr.chartOptions"
                    :series="hourlyVcr.series"
                  />
                </v-card-text> 
              </base-card>
            </v-col> 

            <v-col 
              cols="12"
              xs="12"
              sm="12"
              md="12" 
              lg="6"
              xl="4"
            >
              <base-card> 
                <v-card-title class="d-flex justify-space-between">
                  <div class="mb-0 mr-4">
                    <p class="card-title mb-0 text-uppercase">
                      BIDS
                    </p>
                  </div>
                </v-card-title>
                <v-divider />
              
                <v-card-text>
                  <apexchart
                    ref="hourlyBids"
                    type="line"
                    height="200"
                    :options="hourlyBids.chartOptions"
                    :series="hourlyBids.series"
                  />
                </v-card-text> 
              </base-card>
            </v-col> 

            <v-col 
              cols="12"
              xs="12"
              sm="12"
              md="12" 
              lg="6"
              xl="4"
            >
              <base-card> 
                <v-card-title class="d-flex justify-space-between">
                  <div class="mb-0 mr-4">
                    <p class="card-title mb-0 text-uppercase">
                      WINS
                    </p>
                  </div>
                </v-card-title>
                <v-divider />
              
                <v-card-text>
                  <apexchart
                    ref="hourlyWins"
                    type="line"
                    height="200"
                    :options="hourlyWins.chartOptions"
                    :series="hourlyWins.series"
                  />
                </v-card-text> 
              </base-card>
            </v-col>

            <v-col
              cols="12" 
            >
              <base-card> 
                <v-card-title class="d-flex justify-space-between">
                  <div class="mb-0 mr-4">
                    <p class="card-title mb-0">
                      Hour Trend
                    </p>
                  </div>            
                </v-card-title>
                <v-divider />
              
                <v-card-text>
                  <apexchart
                    ref="hourTrend"
                    type="area"
                    height="320"
                    :options="hourTrend.chartOptions"
                    :series="hourTrend.series"
                  />
                </v-card-text> 
              </base-card>
            </v-col>

            <v-col
              cols="12" 
            >
              <base-card> 
                <v-card-title class="d-flex justify-space-between">
                  <div class="mb-0 mr-4">
                    <p class="card-title mb-0">
                      Daily Trend
                    </p>
                  </div>            
                </v-card-title>
                <v-divider />
              
                <v-card-text>
                  <apexchart
                    ref="dailyTrend"
                    type="area"
                    height="320"
                    :options="dailyTrend.chartOptions"
                    :series="dailyTrend.series"
                  />
                </v-card-text> 
              </base-card>
            </v-col>
          </v-row>
        </v-card-text>
      </template>
    </v-card>
  </o-container>
</template>

<script>
import RestApi from "@/services/RestApi";
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "LineItemChecker",
  mixins: [RestApi],
  props: {
    dialogName: {
      type: String,
      default: "",
    },
  },
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "Line Item Checker",
  },
  data() {
    return {
      title: "Line Item Checker",
      showFilter: false,      
      busy: false,
      data: {},
      valid: false,
      line_item_id: 207281, //206788,
      
      hourlyImpressions: { chartOptions: {}, series: [] },
      hourlySpends: { chartOptions: {}, series: [] },
      hourlyCpm: { chartOptions: {}, series: [] },
      hourlyVcr: { chartOptions: {}, series: [] },
      hourlyBids: { chartOptions: {}, series: [] },
      hourlyWins: { chartOptions: {}, series: [] },

      hourTrend: { chartOptions: {}, series: [] },
      dailyTrend: { chartOptions: {}, series: [] },
    };
  },
  computed: {
    ...mapGetters(["loggedInUser"]), 
  },
  mounted() {
    if (this.$route.query.line_item_id && this.$route.query.line_item_id > 0) {
      this.line_item_id = this.$route.query.line_item_id;
      setTimeout(async () => {        
        this.reset();
      }, 2000);
    }
    
    const hourlyConfig = _.cloneDeep(this.lineChartConfig);
    hourlyConfig.chartOptions.xaxis.categories = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23];

    this.hourlyImpressions = _.cloneDeep(hourlyConfig);
    this.hourlyImpressions.chartOptions.hourKey = 'impressions';
    this.hourlyImpressions.chartOptions.parentControl = this;
    this.hourlySpends = _.cloneDeep(hourlyConfig);
    this.hourlySpends.chartOptions.hourKey = 'spends';
    this.hourlySpends.chartOptions.parentControl = this;
    this.hourlyCpm = _.cloneDeep(hourlyConfig);
    this.hourlyCpm.chartOptions.hourKey = 'cpm';
    this.hourlyCpm.chartOptions.parentControl = this;
    this.hourlyVcr = _.cloneDeep(hourlyConfig);
    this.hourlyVcr.chartOptions.hourKey = 'vcr';
    this.hourlyVcr.chartOptions.parentControl = this;
    this.hourlyBids = _.cloneDeep(hourlyConfig);
    this.hourlyBids.chartOptions.hourKey = 'bids';
    this.hourlyBids.chartOptions.parentControl = this;
    this.hourlyWins = _.cloneDeep(hourlyConfig);
    this.hourlyWins.chartOptions.hourKey = 'winds';
    this.hourlyWins.chartOptions.parentControl = this;

    this.hourTrend = _.cloneDeep(this.customChartConfig); 
    this.hourTrend.chartOptions.parent = this;
    this.hourTrend.chartOptions.markers = {
        size: 0,
        style: 'hollow',
      }
    this.hourTrend.chartOptions.chart.toolbar = true;
    this.hourTrend.chartOptions.xaxis = {
        type: 'datetime', 
        tickAmount: 6,
        categories: []
    }
    this.hourTrend.chartOptions.tooltip = {
      x: {
        format: 'dd MMM yyyy HH:00',
      },
    }    
    this.hourTrend.series = [
      {
        name: 'Impressions',
        type: 'line',
        data: []
      }, 
      {
        name: 'Spends',
        type: 'line',
        data: []
      }, 
      {
        name: 'CPM',
        type: 'line',
        data: []
      },
      {
        name: 'VCR',
        type: 'line',
        data: []
      },
      {
        name: 'Bids',
        type: 'line',
        data: []
      },
      {
        name: 'Wins',
        type: 'line',
        data: []
      }
    ];         
    this.hourTrend.chartOptions.yaxis = [ 
      { 
        opposite: false,
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: '#5d78ff',
        }, 
        title: {
          text: 'IMPRESSIONS',
          style: {
            color: '#5d78ff',
          },
        },
        labels: { 
          style: {
            color: '#5d78ff',
          },
          formatter: function (value, chart) {
            let ret = value
            if (ret > 0) {
              return parseInt(value.toString()).toLocaleString() || '0'
            }
            return ret
          }, 
        }
      },
      { 
        opposite: false,
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: '#fbaf0f',
        }, 
        title: {
          text: 'SPENDS', 
          style: {
            color: '#fbaf0f',
          },
        },
        labels: { 
          style: {
            color: '#fbaf0f',
          },
          formatter: function (value, chart) {
            if (chart && chart.w && chart.w.config && chart.w.config.parent) {
              return chart.w.config.parent.getCurrency(value);
            } else { 
              if (value > 0) {
                return parseInt(value.toString()).toLocaleString() || '0'
              }
              return value
            }
          }, 
        }
      },
      { 
        opposite: false,
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: '#ff5252',
        }, 
        title: {
          text: 'CPM',
          style: {
            color: '#ff5252',
          },
        },
        labels: { 
          style: {
            color: '#ff5252',
          },
          formatter: function (value, chart) {
            if (chart && chart.w && chart.w.config && chart.w.config.parent) {
              return chart.w.config.parent.getCurrency(value);
            } else { 
              if (value > 0) {
                return parseInt(value.toString()).toLocaleString() || '0'
              }
              return value
            }
          }, 
        }
      },
      { 
        opposite: true,
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: '#FF8A48',
        }, 
        title: {
          text: 'VCR',
          style: {
            color: '#FF8A48',
          },
        },
        labels: { 
          style: {
            color: '#FF8A48',
          },
          formatter: function (value, chart) {
            if (chart && chart.w && chart.w.config && chart.w.config.parent) {
              return chart.w.config.parent.getPercent(value);
            } else { 
              if (value > 0) {
                return parseInt(value.toString()).toLocaleString() || '0'
              }
              return value
            }
          }, 
        }
      },
      { 
        opposite: true,
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: '#304156',
        }, 
        title: {
          text: 'BIDS',
          style: {
            color: '#304156',
          },
        },
        labels: { 
          style: {
            color: '#304156',
          }, 
          formatter: function (value, chart) {
            if (chart && chart.w && chart.w.config && chart.w.config.parent) {
              return chart.w.config.parent.getInteger(value);
            } else { 
              if (value > 0) {
                return parseInt(value.toString()).toLocaleString() || '0'
              }
              return value
            }
          }, 
        }
      },
      { 
        opposite: true,
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: '#09B66D',
        }, 
        title: {
          text: 'WINS',
          style: {
            color: '#09B66D',
          },
        },
        labels: { 
          style: {
            color: '#09B66D',
          },
          formatter: function (value, chart) {
            if (chart && chart.w && chart.w.config && chart.w.config.parent) {
              return chart.w.config.parent.getInteger(value);
            } else { 
              if (value > 0) {
                return parseInt(value.toString()).toLocaleString() || '0'
              }
              return value
            }
          }, 
        }
      },
    
    ]

    this.dailyTrend = _.cloneDeep(this.customChartConfig);
    this.dailyTrend.chartOptions.parent = this;
    this.dailyTrend.chartOptions.markers = {
        size: 0,
        style: 'hollow',
      }
    this.dailyTrend.chartOptions.chart.toolbar = true; 
    this.dailyTrend.chartOptions.tooltip = {
      x: {
        format: 'dd MMM yyyy',
      },
    }
    this.dailyTrend.series = [
      {
        name: 'Impressions',
        type: 'line',
        data: []
      }, 
      {
        name: 'Spends',
        type: 'line',
        data: []
      }, 
      {
        name: 'CPM',
        type: 'line',
        data: []
      },
      {
        name: 'VCR',
        type: 'line',
        data: []
      },
      {
        name: 'Bids',
        type: 'line',
        data: []
      },
      {
        name: 'Wins',
        type: 'line',
        data: []
      }
    ];

    this.dailyTrend.chartOptions.yaxis = [ 
      { 
        opposite: false,
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: '#5d78ff',
        }, 
        title: {
          text: 'IMPRESSIONS',
          style: {
            color: '#5d78ff',
          },
        },
        labels: { 
          style: {
            color: '#5d78ff',
          },
          formatter: function (value, chart) {
            let ret = value
            if (ret > 0) {
              return parseInt(value.toString()).toLocaleString() || '0'
            }
            return ret
          }, 
        }
      },
      { 
        opposite: false,
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: '#fbaf0f',
        }, 
        title: {
          text: 'SPENDS', 
          style: {
            color: '#fbaf0f',
          },
        },
        labels: { 
          style: {
            color: '#fbaf0f',
          },
          formatter: function (value, chart) {
            if (chart && chart.w && chart.w.config && chart.w.config.parent) {
              return chart.w.config.parent.getCurrency(value);
            } else { 
              if (value > 0) {
                return parseInt(value.toString()).toLocaleString() || '0'
              }
              return value
            }
          }, 
        }
      },
      { 
        opposite: false,
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: '#ff5252',
        }, 
        title: {
          text: 'CPM',
          style: {
            color: '#ff5252',
          },
        },
        labels: { 
          style: {
            color: '#ff5252',
          },
          formatter: function (value, chart) {
            if (chart && chart.w && chart.w.config && chart.w.config.parent) {
              return chart.w.config.parent.getCurrency(value);
            } else { 
              if (value > 0) {
                return parseInt(value.toString()).toLocaleString() || '0'
              }
              return value
            }
          }, 
        }
      },
      { 
        opposite: true,
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: '#FF8A48',
        }, 
        title: {
          text: 'VCR',
          style: {
            color: '#FF8A48',
          },
        },
        labels: { 
          style: {
            color: '#FF8A48',
          },
          formatter: function (value, chart) {
            if (chart && chart.w && chart.w.config && chart.w.config.parent) {
              return chart.w.config.parent.getPercent(value);
            } else { 
              if (value > 0) {
                return parseInt(value.toString()).toLocaleString() || '0'
              }
              return value
            }
          }, 
        }
      },
      { 
        opposite: true,
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: '#304156',
        }, 
        title: {
          text: 'BIDS',
          style: {
            color: '#304156',
          },
        },
        labels: { 
          style: {
            color: '#304156',
          }, 
          formatter: function (value, chart) {
            if (chart && chart.w && chart.w.config && chart.w.config.parent) {
              return chart.w.config.parent.getInteger(value);
            } else { 
              if (value > 0) {
                return parseInt(value.toString()).toLocaleString() || '0'
              }
              return value
            }
          }, 
        }
      },
      { 
        opposite: true,
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: '#09B66D',
        }, 
        title: {
          text: 'WINS',
          style: {
            color: '#09B66D',
          },
        },
        labels: { 
          style: {
            color: '#09B66D',
          },
          formatter: function (value, chart) {
            if (chart && chart.w && chart.w.config && chart.w.config.parent) {
              return chart.w.config.parent.getInteger(value);
            } else { 
              if (value > 0) {
                return parseInt(value.toString()).toLocaleString() || '0'
              }
              return value
            }
          }, 
        }
      },
    
    ]
  },
  methods: {
    async reset() {
      this.busy = true;
      this.showFilter = false;
      await this.changeQueryString({ line_item_id: this.line_item_id });

      setTimeout(async () => {
        const url = `/rest/line_items/checkLineItem?platform_id=1&account_id=${this.loggedInUser.account_id}&line_item_id=${this.line_item_id}`;

        await this.getData(`${url}`, null).then((response) => {
          if (response.data != {}) {
            
            this.data = _.cloneDeep(response.data);
            this.data.line_item.bidding_status = this.data.bidding_status.passed;
            this.data.bidding_status.conditions = this.data.bidding_status.conditions.map((d) => {
              return {...d, status: d.status == 'valid'}
            })            
            this.setHourly('hourlyImpressions', 'impressions', this.data);
            this.setHourly('hourlySpends', 'spends', this.data);
            this.setHourly('hourlyCpm', 'cpm', this.data);
            this.setHourly('hourlyVcr', 'vcr', this.data);
            this.setHourly('hourlyBids', 'bids', this.data);
            this.setHourly('hourlyWins', 'wins', this.data);
            this.setHourTrend(this.data);
            this.setDailyTrend(this.data);
            
          } else {
            this.$refs.container.snackBar(`No record found.mdi-text-box-search-outline`,
              `red`,
              2000
            );
          }
          setTimeout(() => {
            this.busy = false;
          }, 200);
        });
      }, 200);
    },
    setHourly(ctrl, key, value) {
      const today = value.today_hour;
      const yesterday = value.yesterday_hour;

      const series = [
        {
          name: 'Yesterday',
          type: 'column',
          data: yesterday.map((d) => d[key] > 0 ? (['impressions', 'bids', 'wins'].includes(key) ? this.getInteger(d[key]) : this.getDecimal(d[key]) ) : null)
          //data: yesterday.map((d) => d[key] > 0 ?  d[key] : null)
        },
        {
          name: 'Today',
          type: 'line',
          data: today.map((d) => d[key] > 0 ? (['impressions', 'bids', 'wins'].includes(key) ? this.getInteger(d[key]) : this.getDecimal(d[key]) ) : null)
        }
      ];

      if (this.$refs[ctrl]) { 
        this.$refs[ctrl].updateSeries(series, true);   
      } else {
        console.log('Control not found!!!', ctrl, this.$refs[ctrl], this.$refs)
      }
    },
    setHourTrend(value) {
      const monthly = value.month_hour;
      const xaxis = {
        type: 'datetime', 
        tickAmount: 6,
        categories: monthly.map((d) => {          
          const date = new Date(d.bid_date)           
          return moment(date).format('DD MMM YYYY HH:00')
        })
      }
      const series = []; 
      series.push(
        {
          name: 'Impressions',
          type: 'line',
          data: monthly.map((d) => d.impressions)
        }, 
        {
          name: 'Spends',
          type: 'line',
          data: monthly.map((d) => d.spends)
        }, 
        {
          name: 'CPM',
          type: 'line',
          data: monthly.map((d) => d.cpm)
        },
        {
          name: 'VCR',
          type: 'line',
          data: monthly.map((d) => d.vcr)
        },
        {
          name: 'Bids',
          type: 'line',
          data: monthly.map((d) => d.bids)
        },
        {
          name: 'Wins',
          type: 'line',
          data: monthly.map((d) => d.wins)
        }
      );    
      
      if (this.$refs.hourTrend) {
        this.$refs.hourTrend.updateOptions({xaxis}, false, true);
        this.$refs.hourTrend.updateSeries(series, true);
      }
    },
    setDailyTrend(value) {
      const monthly = value.month_day;
      const xaxis = {
        type: 'datetime', 
        tickAmount: 6,
        categories: monthly.map((d) => {          
          const date = new Date(d.bid_date)           
          return moment(date).format('DD MMM YYYY')
        })
      }
      const series = []; 
      series.push(
        {
          name: 'Impressions',
          type: 'line',
          data: monthly.map((d) => d.impressions)
        }, 
        {
          name: 'Spends',
          type: 'line',
          data: monthly.map((d) => d.spends)
        }, 
        {
          name: 'CPM',
          type: 'line',
          data: monthly.map((d) => d.cpm)
        },
        {
          name: 'VCR',
          type: 'line',
          data: monthly.map((d) => d.vcr)
        },
        {
          name: 'Bids',
          type: 'line',
          data: monthly.map((d) => d.bids)
        },
        {
          name: 'Wins',
          type: 'line',
          data: monthly.map((d) => d.wins)
        }
      );    
      
      if (this.$refs.dailyTrend) {
        this.$refs.dailyTrend.updateOptions({xaxis}, false, true);
        this.$refs.dailyTrend.updateSeries(series, true);
      }
    },
    compare(sd, ed) {
      const start = new Date(sd);
      const end = new Date(ed);
      const now = new Date(this.data.current_date.value);
      if (!(now >= start && now <= end)) return "text-red";
      return "";
    },
    edit(item) {      
      const campaign_id = item.campaign_id;
      this.$router.push(`/app/checker/campaigns?campaign_id=${campaign_id}`);
    }, 
  },
};
</script>
<style lang="scss" scoped>
.o-container-content {
  min-height: calc(100vh - 200px);
}

.json-container {
  max-height: calc(100vh - 425px);
  overflow: scroll;
}
</style>
